.login-page {
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .login-form {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    width: 400px;
  }
  
  .login-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    color: #333;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .form-label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #555;
  }
  
  .form-input {
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease;
  }
  
  .form-input:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .submit-button {
    background-color: #333;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    transition: background-color 0.2s ease;
  }
  
  .submit-button:hover {
    background-color: #222;
  }
  
  .error-message {
    background-color: #ffe4e4;
    border-radius: 5px;
    color: #ff4d4d;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
  }