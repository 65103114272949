/* Hide element on extra small devices */
@media (max-width: 1200px) {
    .hide1200 {
        display: none;
    }
}

@media (max-width: 950px) {
    .hide950 {
        display: none;
    }
}


@media (max-width: 700px) {
    .hide700 {
        display: none;
    }
}

@media (max-width: 500px) {
    .hide500 {
        display: none;
    }
}